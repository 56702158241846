import React from "react";
import "../scss/_thankyouPage.scss";

function ThankyouPage() {
  return (
    <div className="Container-fluid ty-container">
      <h1>Thankyou for contacting Us!</h1>
      <p>Our team will reach you out soon</p>
    </div>
  );
}

export default ThankyouPage;
