import React from "react";
import Post from "./Post";

function Posts() {
  return (
    <div className="container-fluid">
      <div className="posts-container">
        <Post />
        <Post />
        <Post />
        <Post />
        <Post />
        <Post />
        <Post />
        <Post />
        <Post />
        <Post />
      </div>
    </div>
  );
}

export default Posts;
