import React from "react";

function Banner() {
  return (
    <div className="container-fluid">
      <div className="banner-container">
        <h1>Blogs</h1>
      </div>
    </div>
  );
}

export default Banner;
